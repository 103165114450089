<template>
  <div>
    <FormWrapper
      v-if="tokenValid === 1"
      :formTitle="translations('ThinkYourNewPassword')"
      :v="$v.form"
      :formButton="translations('Submit')"
      :successMessage="translations('NewPasswordResetDone')"
      :formCallback="doNewPasswordReset"
      @form-success="goFurther"
    >
      <PasswordField
        v-model="form.password"
        :fieldLabel="translations('EnterNewPassword')"
        :fieldClosed="false"
        :v="$v.form.password"
      />
      <PasswordField
        v-model="form.repeatPassword"
        :fieldLabel="translations('RepeatYourPassword')"
        :fieldClosed="false"
        :v="$v.form.repeatPassword"
      />
    </FormWrapper>
    <h2 v-if="tokenValid === -1">{{ translations("ResetTokenInvalid") }}</h2>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormWrapper from "./FormWrapper";
import PasswordField from "./FieldComponents/PasswordField";
import {
  required,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";

export default {
  name: "ForgotPasswordForm",
  data: () => {
    return {
      form: {
        password: "",
        repeatPassword: "",
      },
      wrapperLoaded: false,
      tokenValid: 0,
      tokenValue: "",
    };
  },
  validations: {
    form: {
      password: { required, minLength: minLength(6), maxLength: maxLength(30) },
      repeatPassword: { sameAsPassword: sameAs("password") },
    },
  },
  components: { FormWrapper, PasswordField },
  computed: {
    ...mapGetters(["translations"]),
  },
  methods: {
    ...mapActions(["newPasswordReset", "validateResetToken"]),
    async doNewPasswordReset(recaptchaToken) {
      console.log("начало смены пароля");
      await this.newPasswordReset({
        password: this.form.password,
        resetToken: this.tokenValue,
        token: recaptchaToken,
      });
    },
    goFurther() {
      setTimeout(() => {
        this.$router.push("/login");
      }, 2000);
    },
  },
  async mounted() {
    const tokenValid = await this.validateResetToken({
      resetToken: this.$route.query.token,
    });
    console.log("tokenValid: ", tokenValid);
    this.tokenValid = parseInt(tokenValid);
    this.tokenValue = this.$route.query.token;
  },
};
</script>